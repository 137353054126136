import { ShipoutShutdownState } from 'swag-common/interfaces';
import { PREFIX } from './settings.actions';
export const GET_SUPER_SPEED_SUCCESS = `${PREFIX}GET_SUPER_SPEED_SUCCESS`;
export const GET_SUPER_SPEED_ERROR = `${PREFIX}GET_SUPER_SPEED_ERROR`;
const payload = {
  config: {
    enabled: false,
    multiplier: 0,
    numOfDays: 1
  }
};
export const getSuperSpeedSettings = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const getSuperSpeedSettingsForProductBuilder = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const validateSuperSpeedItemInCart = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const validateSuperSpeedForCheckout = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS = `${PREFIX}GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS`;
export const GET_SHIPOUT_SHUTDOWN_SETTINGS_ERROR = `${PREFIX}GET_SHIPOUT_SHUTDOWN_SETTINGS_ERROR`;
const payloadShipout = {
  config: {
    state: ShipoutShutdownState.WORKING,
    startDate: null,
    endDate: null
  }
};
export const getShipoutShutdownSettings = () => ({
  type: GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS,
  payload: payloadShipout
});
export const showShipoutShutdownNoticePopup = () => (dispatch, getState) => {
  return;
};